import { BUILD_TIMESTAMP } from '@environments/timestamp';

const packageJson = require('../../package.json');

export const environment = {
  appName: 'Intergrip 2.0',
  production: false,
  hmr: false,
  name: 'acceptance',
  baseUrl: 'https://api-temp.accept.intergrip.nl/api',
  sentryUrl: null,
  webSocketKey: '552e5f3f54b29fbc96de',
  databaseName: 'emendis.complete',
  version: packageJson.version + '.' + BUILD_TIMESTAMP,
  redirectAfterLogin: '/'
};
